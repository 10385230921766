/* Estilos globales */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}

/* Estilos del cuerpo */
body {
  font-family: 'Times New Roman', Times, serif;
  background-color: #FFF8CC;
  width: 100%;
}

/* Estilos de las imágenes */
img {
  width: 100%;
}

/* Contenedor principal del formulario de inicio de sesión */
.login-container {
  height: 43.75em;
  width: 60em;
  margin: 6em auto;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border: 3px solid #000000;
}

/* Contenedor de información de inicio de sesión */
.login-info-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 0.5rem;
  background-color: #ffffff;
}

/* Contenedor de imagen */
.image-container {
  padding: 2rem;
  width: 50%;
  background-color: #041053;
  box-sizing: border-box;
}

/* Contenedor de imagen alternativo */
.image-container2 {
  width: 70%;
  box-sizing: border-box;
}

/* Título del formulario */
.title {
  text-transform: capitalize;
  font-size: 2.25rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: #041053;
}

/* Contenedor de inputs */
.inputs-container {
  height: 55%;
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/* Estilos de los botones */
.btn {
  width: 50%;
  height: 6rem;
  font-size: 1em;
}

/* Estilos de los campos de formulario */
.form-control {
  padding-left: 20px;
  border: none;
  width: 80%;
  height: 6rem;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 1px;
  box-sizing: border-box;
  background-color: #fcffe4;
}

/* Estilos de los mensajes de error */
.errores {
  color: red;
  font-size: 15px;
}

/* Estilos del botón */
.btn {
  width: 70%;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: black;
  border: none;
  border-radius: 5px;
  background-color: #F8D814;
}

/* Estilos de los párrafos dentro del contenedor de inicio de sesión */
.login-info-container>p {
  margin: 0;
}

/* Estilos para pantallas con un ancho máximo de 1000px */
@media screen and (max-width: 1000px) {

  /* Redimensionar el contenedor principal del formulario */
  .login-container {
    width: 70%;
    margin-top: 3rem;
  }

  /* Ajustar el ancho del contenedor de información de inicio de sesión */
  .login-info-container {
    width: 100%;
    border-radius: 5px;
  }

  /* Ocultar el contenedor de imagen */
  .image-container {
    display: none;
  }
}

/* Estilos para pantallas con un ancho máximo de 650px */
@media screen and (max-width: 650px) {

  /* Redimensionar el contenedor principal del formulario */
  .login-container {
    width: 90%;
  }
}

/* Estilos para pantallas con un ancho máximo de 350px */
@media screen and (max-width: 350px) {

  /* Redimensionar la altura del contenedor principal del formulario */
  .login-container {
    height: 20%;
  }

  /* Estilos para los párrafos dentro del contenedor de información de inicio de sesión */
  .login-info-container>p {
    margin: 0;
  }
}


/* Estilos para el contenedor principal */
.container {
  display: flex;
}

/* Estilos para el contenido principal */
main {
  width: 100%;
  padding: 20px;
}

/* Estilos para el título h3 */
h3 {
  text-align: center;
  font-size: 35px;
  margin-bottom: 20px;
  font-family: 'Times New Roman', Times, serif;
  color: #180045;
  text-shadow: 2px 2px 4px rgba(255, 248, 105, 0.816);
}

/* Define los estilos para elemento de la barra lateral.  */
.sidebar {
  background: #060324;
  color: #fff;
  height: 250vh;
  /* Establecer la altura al 100% */
  transition: all 0.5s;
}

/* Define una clase  llamada "top_section". Esta clase se está utilizando para
diseñar una sección de la página web. */
.top_section {
  display: flex;
  align-items: center;
  padding: 30px 15px;
}

/* Define una clase CSS llamada "barras". Esta clase se aplica a los elementos para
mostrarlos como un contenedor flexible */
.bars {
  display: flex;
  font-size: 25px;
  margin-left: 50px;
}

/* Definimos los enlaces". */
.link {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
}

/*Le aplicamos a los enlaces en efect hover*/
.link:hover {
  background: #fada00;
  color: #000;
  transition: all 0.5s;
}

/* Estilos para la clase .active */
.active {
  background: #fada00;
  color: #000;
}

/* Estilos para el título h1 */
h1 {
  text-align: center;
}

/* Estilos para el contenedor de imagen */
.image-container2 {
  border-radius: 90px;
  width: 90%;
  background-color: #041053;
  display: "block";
}

/* Estilos para la tabla */
table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  margin: 40px auto;
  width: 900px;
}

th,
td {
  padding: 10px;
  text-align: center;
}

thead {
  background-color: #060324;
  color: white;
}

td {
  background-color: #24303c;
  color: white;
  border: 1px solid #fffefe;
}

/* Estilos para el botón .btn-b */
.btn-b {
  background-color: #ff0000;
  color: #ffffff;
  border: none;
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-b:hover {
  background-color: #cc0000;
}

.btn-b:focus {
  outline: none;
}

/* Estilos para el botón .btn-m */
.btn-m {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

/* Estilos para el botón .btn-a */
.btn-a {
  background-color: #00ff00;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-a:hover {
  background-color: #00cc00;
}

.btn-a:focus {
  outline: none;
}

/* Estilos hover y focus para el botón .btn-m */
.btn-m:hover {
  background-color: #0069d9;
}

.btn-m:focus {
  outline: none;
}

@media (max-width: 1040px) {

  /* Cuando la pantalla tiene un ancho máximo de 1040px */
  table {
    width: 80%;
    /* Reduce el ancho de la tabla al 80% */
    font-size: 16px;
  }

  .btn-b {
    margin-top: 20px;
  }
}

@media (max-width: 880px) {

  /* Cuando la pantalla tiene un ancho máximo de 880px */
  table {
    width: 60%;
    font-size: 16px;
  }

  table tr {
    display: flex;
    /* Muestra las filas de la tabla como columnas flexibles */
    flex-direction: column;
    /* Dirección de apilado vertical */
    padding: 10px;
    /* Espaciado interno de las filas */
  }

  table td[data-titulo] {
    display: flex;
    /* Muestra las celdas de datos como elementos flexibles */
  }

  table td,
  table th {
    border: none;
    /* Elimina los bordes de las celdas */
  }

  table td[data-titulo]::before {
    content: attr(data-titulo);
    /* Agrega el título antes del contenido de la celda */
    width: 80px;
    /* Ancho del título */
    color: #fada00;
    /* Color del texto del título */
  }

  table thead {
    display: none;
    /* Oculta el encabezado de la tabla */
  }

  .btn-m {
    margin-right: 10px;
    /* Margen derecho para el botón .btn-m */
  }
}

@media (max-width: 410px) {

  /* Cuando la pantalla tiene un ancho máximo de 410px */
  table {
    width: 30%;
    /* Reduce el ancho de la tabla al 30% */
    font-size: 12px;
    /* Tamaño de fuente de la tabla */
  }

  table tr {
    display: flex;
    /* Muestra las filas de la tabla como columnas flexibles */
    flex-direction: column;
    /* Dirección de apilado vertical */
  }

  table td[data-titulo] {
    display: flex;
    /* Muestra las celdas de datos como elementos flexibles */
  }

  .btn-m,
  .btn-b {
    padding: 10px 10px;
    /* Espaciado interno de los botones */
    font-size: 14px;
    /* Tamaño de fuente de los botones */
  }
}

@media (max-width: 356px) {

  /* Cuando la pantalla tiene un ancho máximo de 356px */
  table {
    width: 20%;
    /* Reduce el ancho de la tabla al 20% */
    margin-right: 80px;
    /* Margen derecho para la tabla */
    font-size: 10px;
    /* Tamaño de fuente de la tabla */
  }

  .table2 {
    margin-right: 100px;
    /* Margen derecho para la tabla con clase .table2 */
    font-size: 8px;
    /* Tamaño de fuente de la tabla con clase .table2 */
  }

  .profesores {
    margin-right: 40px;
    /* Margen derecho para el título con clase .profesores */
  }

  .vehiculos {
    margin-right: 60px;
    /* Margen derecho para el título con clase .vehiculos */
  }

  .alumnos {
    margin-right: 30px;
    /* Margen derecho para el título con clase .alumnos */
  }

  .clases {
    margin-right: 70px;
    /* Margen derecho para el título con clase .clases */
  }

  table tr {
    flex-direction: column;
    /* Dirección de apilado vertical para las filas de la tabla */
  }

  table td[data-titulo] {
    display: flex;
    /* Muestra las celdas de datos como elementos flexibles */
  }

  .btn-m,
  .btn-b {
    padding: 10px 10px;
    /* Espaciado interno de los botones */
    font-size: 12px;
    /* Tamaño de fuente de los botones */
  }
}

/* FORMULARIO DE CREATE*/

/* Define la clase llamada "crear". y le da sus estilos correspondientes */
.create {
  width: 400px;
  background: #24303c;
  padding: 30px;
  margin: auto;
  margin-top: 100px;
  border-radius: 4px;
  font-family: 'calibri';
  color: white;
  box-shadow: 7px 13px 37px #000;
}

.create h4 {
  font-size: 22px;
  /* Tamaño de fuente del encabezado h4 */
  margin-bottom: 20px;
  /* Margen inferior del encabezado h4 */
}

.controls {
  width: 100%;
  /* Ancho del control */
  background: #24303c;
  /* Color de fondo del control */
  padding: 10px;
  /* Espaciado interno del control */
  margin: 16px 0;
  /* Margen vertical del control */
  border-radius: 4px;
  /* Borde redondeado del control */
  margin-bottom: 16px;
  /* Margen inferior del control */
  border: 1px solid #F8D814;
  /* Borde del control */
  font-family: 'calibri';
  /* Fuente del control */
  font-size: 18px;
  /* Tamaño de fuente del control */
  color: white;
  /* Color del texto del control */
}

.create .botons {
  width: 100%;
  /* Ancho del botón */
  background: #F8D814;
  /* Color de fondo del botón */
  border: none;
  /* Sin borde */
  padding: 12px;
  /* Espaciado interno del botón */
  color: white;
  /* Color del texto del botón */
  margin: 16px 0;
  /* Margen vertical del botón */
  font-size: 16px;
  /* Tamaño de fuente del botón */
}

/* Media Queries para pantallas con un ancho máximo de 600px */
@media (max-width: 600px) {
  .create {
    width: 70%;
    /* Ancho del contenedor de creación */
    margin-top: 50px;
    /* Margen superior del contenedor de creación */
    font-size: 14px;
    /* Tamaño de fuente del contenedor de creación */
  }

  .create h4 {
    font-size: 18px;
    /* Tamaño de fuente del encabezado h4 en pantallas pequeñas */
    margin-bottom: 15px;
    /* Margen inferior del encabezado h4 en pantallas pequeñas */
  }

  .controls {
    margin: 10px 0;
    /* Margen vertical de los controles en pantallas pequeñas */
    margin-bottom: 10px;
    /* Margen inferior de los controles en pantallas pequeñas */
    font-size: 14px;
    /* Tamaño de fuente de los controles en pantallas pequeñas */
  }

  .create .botons {
    font-size: 14px;
    /* Tamaño de fuente de los botones en pantallas pequeñas */
  }
}

/* Estilos para las cards */
.container-cards {
  max-width: 1100px;
  /* Ancho máximo del contenedor de cards */
  width: 100%;
  margin: auto;
  padding: 0 20px;
}

section h1 {
  justify-self: center;
  margin-bottom: 40px;
  color: #111;
  font-size: 50px;
  line-height: 60px;
  text-align: center;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.cards2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.card {
  flex: 0 0 calc(33.33%);
  /* Ancho de cada card */
  max-width: calc(30.33%);
  width: 100%;
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: 2px solid transparent;
  transition: 0.3s;
}

.card:hover {
  border-color: #1d39c4;
  /* Color del borde de la card al pasar el cursor */
}

.card h3 {
  font-size: 24px;
  color: #1d39c4;
  text-align: center;
  margin-bottom: 20px;
}

.card img {
  max-width: 400px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.button-container {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.card .btn-cards {
  justify-content: left;
  max-width: 120px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 10px;
  height: 40px;
  margin-top: 20px;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
}

.card .btn-cards:hover {
  color: #1d39c4;
  background: transparent;
}

.btn-1 {
  background-color: blue;
  border: none;
  text-decoration: none;
  text-align: center;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-2 {
  background-color: green;
  border: none;
  text-decoration: none;
  text-align: center;
  padding: 10px 10px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
}

/* Media Queries para pantallas con un ancho máximo de 1080px */
@media (max-width: 1080px) {
  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  .card {
    flex: 0 0 calc(50%);
    /* Ancho de las cards en pantallas medianas */
    max-width: calc(40%);
    width: 80%;
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    border: 2px solid transparent;
    transition: 0.3s;
  }
}

/* Media Queries para pantallas con un ancho máximo de 870px */
@media (max-width: 870px) {
  .card {
    flex: 0 0 80%;
    /* Ancho de las cards en pantallas pequeñas */
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cards {
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .cards2 {
    display: none;
  }

  .btn-1 {
    max-width: 100%;
    margin-bottom: 10px;
  }

  .btn-2 {
    display: block;
    margin-bottom: 10px;
  }
}

h2 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
}

p {
  margin-top: 20px;
  text-align: center;
}